
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Home",
    "icon": "home-outline",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/channels",
    "label": "Channels",
    "icon": "card-outline",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/business",
    "label": "Business",
    "icon": "briefcase-outline",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/businesstransactions",
        "label": "Business Transactions",
        "icon": "analytics-outline",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/events",
    "label": "Events",
    "icon": "calendar-outline",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/categories",
        "label": "Event Categories",
        "icon": "medical-outline",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/chama",
    "label": "Chama",
    "icon": "people-outline",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/chamacontributions",
        "label": "Chama Contributions",
        "icon": "receipt-outline",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/fundcases",
    "label": "NYETSWA cases",
    "icon": "folder-outline",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/fundcasecollection",
        "label": "NYETSWA  Case Collection",
        "icon": "receipt-outline",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/roles",
    "label": "Roles",
    "icon": "key-outline",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/permissions",
        "label": "Permissions",
        "icon": "shield-half-outline",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  },
  {
    "to": "/transactions",
    "label": "Transactions",
    "icon": "receipt-outline",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/withdrawals",
    "label": "Withdrawals",
    "icon": "paper-plane-outline",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/users",
    "label": "Users",
    "icon": "people-outline",
    "iconcolor": "",
    "target": "",
    
  }
],
	type: [    
{value: "bank", label: "Bank"},
	{value: "paybill", label: "Paybill"},
	{value: "till", label: "Till"}
    ],
	eventStatus: [    
{value: "active", label: "Open"},
	{value: "complete", label: "Complete"}
    ],
	transactionStatus: [{"label":"Paid","value":"paid"},{"label":"Pending","value":"pending"}],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}

import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//business routes
			{
				path: '/business/:fieldName?/:fieldValue?',
				name: 'businesslist',
				component: () => import('./pages/business/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/business/view/:id', 
				name: 'businessview', 
				component: () => import('./pages/business/view.vue'), 
				props: true
			},
		
			{ 
				path: '/business/add', 
				name: 'businessadd', 
				component: () => import('./pages/business/add.vue'), 
				props: true
			},
	
			{ 
				path: '/business/edit/:id', 
				name: 'businessedit', 
				component: () => import('./pages/business/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/business/lipa/:id', 
				name: 'businesslipa', 
				component: () => import('./pages/business/lipa.vue'), 
				props: true
			},
		
			{ 
				path: '/business/pay/:id', 
				name: 'businesspay', 
				component: () => import('./pages/business/pay.vue'), 
				props: true
			},
		

//businesstransactions routes
			{
				path: '/businesstransactions/:fieldName?/:fieldValue?',
				name: 'businesstransactionslist',
				component: () => import('./pages/businesstransactions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/businesstransactions/view/:id', 
				name: 'businesstransactionsview', 
				component: () => import('./pages/businesstransactions/view.vue'), 
				props: true
			},
		
			{
				path: '/businesstransactions/show/:fieldName?/:fieldValue?',
				name: 'businesstransactionsshow',
				component: () => import('./pages/businesstransactions/show.vue'), 
				props: route => passRouteToProps(route)
			},
	

//categories routes
			{
				path: '/categories/:fieldName?/:fieldValue?',
				name: 'categorieslist',
				component: () => import('./pages/categories/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/categories/add', 
				name: 'categoriesadd', 
				component: () => import('./pages/categories/add.vue'), 
				props: true
			},
	
			{ 
				path: '/categories/edit/:id', 
				name: 'categoriesedit', 
				component: () => import('./pages/categories/edit.vue'), 
				props: true
			},
		

//chama routes
			{
				path: '/chama/:fieldName?/:fieldValue?',
				name: 'chamalist',
				component: () => import('./pages/chama/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/chama/view/:id', 
				name: 'chamaview', 
				component: () => import('./pages/chama/view.vue'), 
				props: true
			},
		
			{ 
				path: '/chama/add', 
				name: 'chamaadd', 
				component: () => import('./pages/chama/add.vue'), 
				props: true
			},
	
			{ 
				path: '/chama/edit/:id', 
				name: 'chamaedit', 
				component: () => import('./pages/chama/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/chama/pay/:id', 
				name: 'chamapay', 
				component: () => import('./pages/chama/pay.vue'), 
				props: true
			},
		

//chamacontributions routes
			{
				path: '/chamacontributions/:fieldName?/:fieldValue?',
				name: 'chamacontributionslist',
				component: () => import('./pages/chamacontributions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/chamacontributions/view/:id', 
				name: 'chamacontributionsview', 
				component: () => import('./pages/chamacontributions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/chamacontributions/add', 
				name: 'chamacontributionsadd', 
				component: () => import('./pages/chamacontributions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/chamacontributions/edit/:id', 
				name: 'chamacontributionsedit', 
				component: () => import('./pages/chamacontributions/edit.vue'), 
				props: true
			},
		
			{
				path: '/chamacontributions/show/:fieldName?/:fieldValue?',
				name: 'chamacontributionsshow',
				component: () => import('./pages/chamacontributions/show.vue'), 
				props: route => passRouteToProps(route)
			},
	

//channels routes
			{
				path: '/channels/:fieldName?/:fieldValue?',
				name: 'channelslist',
				component: () => import('./pages/channels/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/channels/view/:id', 
				name: 'channelsview', 
				component: () => import('./pages/channels/view.vue'), 
				props: true
			},
		
			{ 
				path: '/channels/add', 
				name: 'channelsadd', 
				component: () => import('./pages/channels/add.vue'), 
				props: true
			},
	
			{ 
				path: '/channels/edit/:id', 
				name: 'channelsedit', 
				component: () => import('./pages/channels/edit.vue'), 
				props: true
			},
		

//entries routes
			{
				path: '/entries/:fieldName?/:fieldValue?',
				name: 'entrieslist',
				component: () => import('./pages/entries/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/entries/view/:id', 
				name: 'entriesview', 
				component: () => import('./pages/entries/view.vue'), 
				props: true
			},
		
			{ 
				path: '/entries/add', 
				name: 'entriesadd', 
				component: () => import('./pages/entries/add.vue'), 
				props: true
			},
	
			{ 
				path: '/entries/edit/:id', 
				name: 'entriesedit', 
				component: () => import('./pages/entries/edit.vue'), 
				props: true
			},
		
			{
				path: '/entries/adminlist/:fieldName?/:fieldValue?',
				name: 'entriesadminlist',
				component: () => import('./pages/entries/adminlist.vue'), 
				props: route => passRouteToProps(route)
			},
	

//events routes
			{
				path: '/events/:fieldName?/:fieldValue?',
				name: 'eventslist',
				component: () => import('./pages/events/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/events/view/:id', 
				name: 'eventsview', 
				component: () => import('./pages/events/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'eventsuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/events/add', 
				name: 'eventsadd', 
				component: () => import('./pages/events/add.vue'), 
				props: true
			},
	
			{ 
				path: '/events/edit/:id', 
				name: 'eventsedit', 
				component: () => import('./pages/events/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/events/register/:id', 
				name: 'eventsregister', 
				component: () => import('./pages/events/register.vue'), 
				props: true
			},
		
			{
				path: '/events/adminlist/:fieldName?/:fieldValue?',
				name: 'eventsadminlist',
				component: () => import('./pages/events/adminlist.vue'), 
				props: route => passRouteToProps(route)
			},
	

//fundcasecollection routes
			{
				path: '/fundcasecollection/:fieldName?/:fieldValue?',
				name: 'fundcasecollectionlist',
				component: () => import('./pages/fundcasecollection/list.vue'), 
				props: route => passRouteToProps(route)
			},
	

//fundcases routes
			{
				path: '/fundcases/:fieldName?/:fieldValue?',
				name: 'fundcaseslist',
				component: () => import('./pages/fundcases/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/fundcases/view/:id', 
				name: 'fundcasesview', 
				component: () => import('./pages/fundcases/view.vue'), 
				props: true
			},
		
			{ 
				path: '/fundcases/add', 
				name: 'fundcasesadd', 
				component: () => import('./pages/fundcases/add.vue'), 
				props: true
			},
	
			{ 
				path: '/fundcases/edit/:id', 
				name: 'fundcasesedit', 
				component: () => import('./pages/fundcases/edit.vue'), 
				props: true
			},
		
			{ 
				path: '/fundcases/pay/:id', 
				name: 'fundcasespay', 
				component: () => import('./pages/fundcases/pay.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//settings routes
			{
				path: '/settings/:fieldName?/:fieldValue?',
				name: 'settingslist',
				component: () => import('./pages/settings/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/settings/view/:id', 
				name: 'settingsview', 
				component: () => import('./pages/settings/view.vue'), 
				props: true
			},
		
			{ 
				path: '/settings/add', 
				name: 'settingsadd', 
				component: () => import('./pages/settings/add.vue'), 
				props: true
			},
	
			{ 
				path: '/settings/edit/:id', 
				name: 'settingsedit', 
				component: () => import('./pages/settings/edit.vue'), 
				props: true
			},
		

//transactions routes
			{
				path: '/transactions/:fieldName?/:fieldValue?',
				name: 'transactionslist',
				component: () => import('./pages/transactions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/transactions/view/:id', 
				name: 'transactionsview', 
				component: () => import('./pages/transactions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/transactions/add', 
				name: 'transactionsadd', 
				component: () => import('./pages/transactions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/transactions/edit/:id', 
				name: 'transactionsedit', 
				component: () => import('./pages/transactions/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usersuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	

//withdrawals routes
			{
				path: '/withdrawals/:fieldName?/:fieldValue?',
				name: 'withdrawalslist',
				component: () => import('./pages/withdrawals/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/withdrawals/view/:id', 
				name: 'withdrawalsview', 
				component: () => import('./pages/withdrawals/view.vue'), 
				props: true
			},
		
			{ 
				path: '/withdrawals/add', 
				name: 'withdrawalsadd', 
				component: () => import('./pages/withdrawals/add.vue'), 
				props: true
			},
	
			{ 
				path: '/withdrawals/edit/:id', 
				name: 'withdrawalsedit', 
				component: () => import('./pages/withdrawals/edit.vue'), 
				props: true
			},
		

			{ 
				path: '/select_action', 
				name: 'select_action', 
				component: () => import('././pages/custom/select_action.vue'), 
				props: true
			},
	
			{ 
				path: '/account_settings', 
				name: 'account_settings', 
				component: () => import('././pages/custom/account_settings.vue'), 
				props: true
			},
	
			{ 
				path: '/info', 
				name: 'info', 
				component: () => import('././pages/custom/info.vue'), 
				props: true
			},
	
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
		{ 
			path: '/index/accountcreated', 
			name: 'accountcreated', 
			component: () => import('./pages/index/accountcreated.vue'), 
			props: true
		},
		{
			path: '/index/accountpending', 
			name: 'accountpending', 
			component: () => import('./pages/index/accountpending.vue'), 
			props: true
		},
		{ 
			path: '/index/accountblocked',
			name: 'accountblocked', 
			component: () => import('./pages/index/accountblocked.vue'), 
			props: true
		},

	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
